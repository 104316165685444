
import request from '@/utils/request';



class PMApi {
    

    setup_new_pm = (data) => {
        return request({
            url: '/adm/pm/setup_new_pm',
            method: 'post',
            data
        });	
    }
    

    list = (data) => {

        return request({
            url: '/adm/pm/pma/rta_list',
            method: 'post',
            data
        });	

    }

    detail = (data) => {

        return request({
            url: '/adm/pm/pm_detail',
            method: 'post',
            data
        });	
    }



    type_lsit = () => {
        return request({
            url: '/adm/config/pm/pm_type_list',
            method: 'post',
        });	
    }




    delete_landlord =(data) => {
        return request({
            url: '/adm//pm/landlord/landlord_delete',
            method: 'post',
            data
        });	
    }

    delete_folder =(data) => {
        return request({
            url: '/adm/pm/listing_folder_remove',
            method: 'post',
            data
        });	
    }

    delete_supplier =(data) => {
        return request({
            url: '/adm/pm/supplier/supplier_delete',
            method: 'post',
            data
        });	
    }

    delete_file =(data) => {
        return request({
            url: '/adm/pm/listing_file_remove',
            method: 'post',
            data
        });	
    }


    add_property= (data) => {
        return request({
            url: '/adm/pm/listing_add_new_property',
            method: 'post',
            data
        });	
    }


    update_landlord_bank_info = (data) => {
        return request({
            url: '/adm/pm/landlord/pm_update_landlord',
            method: 'post',
            data
        });	
    }


    pm_monthly_rent_pad_file = (data) => {
        return request({
            url: '/adm/pm/batch_post_pms_pad_rent',
            method: 'post',
            data
        });	
        
    }


    batch_deposit_rent_cheque = (data) => {
        return request({
            url: '/adm/pm/batch_post_pms_cheque_rent',
            method: 'post',
            data
        });	
        
    }

    batch_deposit_rent_etransfer = (data) => {
        return request({
            url: '/adm/pm/batch_post_pms_etransfer_rent',
            method: 'post',
            data
        });	
        
    }

    batch_expense = (data) => {
        return request({
            url: '/adm/pm/expense/batch_post_pm_expenses',
            method: 'post',
            data
        });	
        
    }

    delete_expense = (data) => {
        return request({
            url: '/adm/pm/expense/delete_expense',
            method: 'post',
            data
        });	
    }

    

    query_pm_list_by_payment_type = (data) => {
        return request({
            url: '/adm/pm/query_pm_list_by_payment_type',
            method: 'post',
            data
        });	
    }

    setup_pm_pad_file = (data) => {
        return request({
            url: '/adm/pm/batch_post_pms_pad_rent',
            method: 'post',
            data
        });	
        
    }

    query_pm_list_for_close_month_by_searched = (data) => {
        return request({
            url: '/adm/pm/rent/query_rta_list_for_payment',
            method: 'post',
            data
        });	
    }



    landlord_add_property = (data) => {
        return request({
            url: '/adm/pm/landlord/landlord_add_property',
            method: 'post',
            data
        });	
    }

    add_landlord = (data) => {
        return request({
            url: '/adm/pm/landlord/pm_add_landlord',
            method: 'post',
            data
        });	
    }


    terminate_pm = (data) => {
        return request({
            url: '/adm/pm/pm_terminate',
            method: 'post',
            data
        });	
    }

    deposit_release = (data) => {
        return request({
            url: '/adm/pm/rent/release_deposit',
            method: 'post',
            data
        });	
    }

    update_tenant_payment_type = (data) => {
        return request({
            url: '/adm/pm/pma/pm_update_tenant_payment',
            method: 'post',
            data
        });	
    }

    remove_tenant = (data) => {
        return request({
            url: '/adm/pm/pma/remove_extra_tenant',
            method: 'post',
            data
        });	
    }

    get_rta_tenants = (data) => {
        return request({
            url: '/adm/pm/pma/get_rta_tenants',
            method: 'post',
            data
        });	
    }



    query_pm_close_progress = (data) => {
        return request({
            url: '/adm/pm/query_pm_close_task_progress',
            method: 'post',
            data
        });	
    }


    generate_pad_file = (data) => {
        return request({
            url: '/adm/pm/form/download_pad_form_file',
            method: 'post',
            data
        });	
        
    }

    upload_rent_split_out_form = (data) => {
        return request({
            url: '/adm/pm/form/upload_split_out_form',
            method: 'post',
            data
        });	
        
    }

    update_rent_type = (data) => {
        return request({
            url: '/adm/pm/rent/adjust_rent_detail',
            method: 'post',
            data
        });	
    }

    transfer_balance = (data) => {
        return request({
            url: '/adm/pm/rent/transfer_balance',
            method: 'post',
            data
        });	
    }


    nsf_rent = (data) => {
        return request({
            url: '/adm/pm/rent/mark_rent_nsf',
            method: 'post',
            data
        });	
    }

    cancel_ap_record = (data) => {
        return request({
            url: '/adm/pm/rent/cancel_ap_record',
            method: 'post',
            data
        });	
    }

    reverse_ap_transaction = (data) => {
        return request({
            url: '/adm/pm/rent/reverse_ap_transaction',
            method: 'post',
            data
        });	
    }

    update_expense_type = (data) => {
        return request({
            url: '/adm/pm/expense/modify_expense_type',
            method: 'post',
            data
        });	

        
    }


    update_rent = (data) => {
        return request({
            url: '/adm/pm/pm_update_monthly_rent',
            method: 'post',
            data
        });	
        
    }

    update_commission = (data) => {
        return request({
            url: '/adm/pm/pm_update_comission_portion',
            method: 'post',
            data
        });	
        
    }

    
    post_pm_ap = (data) => {
        return request({
            url: '/adm/pm/rent/post_split_rent',
            method: 'post',
            data
        });	

        
    }

    query_pm_statement = (data) => {
        return request({
            url: '/adm/pm/statement/query_statement',
            method: 'post',
            data
        });	
    }

    download_pm_statement = (data) => {
        return request({
            url: '/adm/pm/statement/create_statement_pdf',
            method: 'post',
            data
        });	
    }

    export_statement_list = (data) => {
        return request({
            url: '/adm/pm/statement/export_batch_statement_pdfs',
            method: 'post',
            data
        });	
    }

    query_export_job_status = (data) => {
        return request({
            url: '/adm/pm/statement/query_export_job_status',
            method: 'post',
            data
        });	
    }

    pma_list = (data) => {
        return request({
            url: '/adm/pm/pma/get_pma_list',
            method: 'post',
            data
        });	
    }

    terminate_agent_all_rtas = (data) => {
        return request({
            url: '/adm/pm/pma/terminate_agent_all_rtas',
            method: 'post',
            data
        });	
    }

    agent_all_rta_list = (data) => {
        return request({
            url: '/adm/pm/pma/query_agent_all_rtas',
            method: 'post',
            data
        });	
        
    }

    get_owner_by_pma = (data) => {
        return request({
            url: '/adm/pm/landlord/query_landlord_by_pma',
            method: 'post',
            data
        });	
    }

    export_pm = (data) => {
        return request({
            url: '/adm/pm/form/export_pm_list',
            method: 'post',
            data
        });	
    }


    submit_payment = (data) => {
        return request({
            url: '/adm/pm/rent/make_pm_ap_payment',
            method: 'post',
            data
        });	
    }

    payment_job_status_query = (data) => {
        return request({
            url: '/adm/pm/rent/payment_job_status_query',
            method: 'post',
            data
        });	
    }

    query_pm_expense_total = (data) => {
        return request({
            url: '/adm/pm/expense/query_pm_expense',
            method: 'post',
            data
        });	
    }


    export_owner_list = (data) => {
        return request({
            url: '/adm/pm/landlord/owner_export',
            method: 'post',
            data
        });	
    }


    query_statement_email_list = (data) => {
        return request({
            url: '/adm/pm/statement/query_owner_emails',
            method: 'post',
            data
        });	
    }


    send_statement = (data) => {
        return request({
            url: '/adm/pm/statement/send_statement_mail_job',
            method: 'post',
            data
        });	

    }


    transfer_rta_balance = (data) => {
        return request({
            url: '/adm/pm/rent/transfer_pm_balance',
            method: 'post',
            data
        });	
    }
    


    query_agent_post = (data) => {
        return request({
            url: '/adm/pm/rent/query_agent_ap_post',
            method: 'post',
            data
        });	
    }


    submit_agent_post = (data) => {
        return request({
            url: '/adm/pm/rent/process_agent_post',
            method: 'post',
            data
        });	
        
    }


    query_pm_rent_detail = (data) => {
        return request({
            url: '/adm/pm/statement/query_pm_rent_break_down',
            method: 'post',
            data
        });	
    }

}




let _api = null

const getPMApi = () => {
    if (!_api) {
        _api = new PMApi();
    }
    return _api;
}

export { getPMApi };


